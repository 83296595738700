import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Pager from "../components/elements/pager-buttons"
import Breadcrumb from "../components/elements/breadcrumb"
import ContentToHTML from "../components/elements/content-html"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"

const NewsPage = ({ data, pageContext }) => {
  const asmetHome = useAsmetHome()
  const queriedNoticias = data.allStrapiMediaNoticia.edges

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/noticias`, label: "Noticias" },
  ]

  return (
    <Layout meta={{ og_titulo: "Noticias" }}>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">
                Asmet Noticias
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="asm-media-main container my-5">
        <div id="asm-media-list-cards" className="row row-cols-1">
          {queriedNoticias.map((noticia, index) => {
            return (
              <div className="col mb-4" key={index}>
                <div className="card rounded-0 border-0">
                  <div className="row no-gutters position-relative">
                    <div className="col-4 col-sm-2 card-date text-white-alpha p-3">
                      <span className="card-dd display-4 text-300 d-block">
                        <Moment format="DD">{noticia.node.fecha}</Moment>
                      </span>
                      <span className="card-mm text-100 d-block">
                        <Moment format="MMMM">{noticia.node.fecha}</Moment>
                      </span>
                      <span className="card-yy text-100 d-block">
                        <Moment format="YYYY">{noticia.node.fecha}</Moment>
                      </span>
                    </div>
                    <div className="col-8 col-sm-4 card-cover">
                      <img
                        src={
                          noticia.node.imagen_principal
                            ? noticia.node.imagen_principal.publicURL
                            : "http://placehold.it/300x180"
                        }
                        alt={noticia.node.titulo}
                        className="card-img rounded-right w-100"
                      />
                    </div>
                    <div className="col-12 col-sm-6 position-static pl-0 pl-sm-4 mt-3 mt-sm-0">
                      <i className="fa fa-calendar text-blue-alpha position-absolute d-none d-sm-block"></i>{" "}
                      <p className="card-title text-500 ml-0 ml-sm-4">
                        {noticia.node.titulo}
                      </p>
                      <div className="card-text ml-0 ml-sm-4 mt-n3">
                        <ContentToHTML content={noticia.node.descripcion} />
                      </div>
                      <Link
                        to={`/noticias/${noticia.node.slug}`}
                        className="stretched-link"
                      >
                        {""}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Pager pageContext={pageContext} />
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query NoticesQueryPagination($skip: Int!, $limit: Int!) {
    allStrapiMediaNoticia(
      sort: { order: DESC, fields: fecha }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          strapiId
          slug
          titulo
          meta {
            og_titulo
            og_imagen {
              publicURL
            }
            og_descripcion
          }
          fecha
          imagen_principal {
            publicURL
          }
          descripcion

          contenido {
            id
            tipo_componente

            # Component Texto

            contenido

            # Component Documento

            titulo
            fecha_publicacion
            es_descargable
            descripcion
            archivo {
              publicURL
              extension
            }

            # Component Banner

            slides {
              tipo_componente
              titulo
              subtitulo
              imagen {
                publicURL
              }
              link {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
            }

            # Component Card

            imagen {
              publicURL
            }
            link {
              nombre
              tipo
              link_id
              icono {
                publicURL
              }
            }

            # Component Grupo Cards

            cards {
              tipo_componente
              titulo
              descripcion
              imagen {
                publicURL
              }
              link {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
