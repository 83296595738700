import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <nav id="asm-pager-buttons" className="d-flex justify-content-between">
      <div className="align-self-start">
        {previousPagePath && <Link to={previousPagePath}>Atrás</Link>}
      </div>
      <div className="align-self-end">
        {nextPagePath && <Link to={nextPagePath}>Siguiente</Link>}
      </div>
    </nav>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Pager
